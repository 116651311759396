import ChatEmpty from 'components/Svg/ChatEmpty';
import AnalogPhone from 'components/Svg/AnalogPhone';
import NewspageLogo from 'components/Svg/NewspageLogo';
import Settings from 'components/Svg/Settings';
import Book from 'components/Svg/Book';
import Quote from 'components/Svg/Quote2';
import Rss from 'components/Svg/Rss';
import ProfileIcon from 'components/Svg/Profile';
import Star from 'components/Svg/Star';
import styles from 'styles/modules/Nav.module.scss';

const navigationData = [
    {
        title: 'Home',
        url: '',
        icon: (color) => <NewspageLogo fill={color} />,
        company: true,
        journalist: false,
    },
    {
        title: 'Profile',
        url: '',
        icon: (color) => <ProfileIcon fill={color} />,
        company: false,
        journalist: true,
    },
    {
        title: 'Alerts',
        url: '/news-alerts',
        icon: (color) => <ChatEmpty stroke={color} />,
        company: true,
        journalist: true,
    },
    {
        title: 'News Chat',
        url: '/news-chat',
        icon: (color) => <AnalogPhone fill={color} />,
        company: true,
        journalist: true,
        absolute: true,
        tooltip: 'Got an idea for a story? Discuss it with a journalist',
    },
    {
        title: 'Coverage',
        url: '/media-coverage',
        icon: (color) => <Quote fill={color} />,
        company: true,
        journalist: false,
    },
    {
        title: 'Stories',
        url: '/news-story',
        icon: (color) => <Book fill={color} />,
        company: true,
        journalist: false,
    },
    {
        title: 'Upgrade to Premium',
        url: '/subscription',
        icon: () => (
            <div className={styles['upgrade-to-premium-item']}>
                <Star />
                <div className={[styles.ribbon].join()} />
            </div>
        ),
        company: true,
        journalist: false,
    },
    {
        title: 'Settings',
        url: '/settings',
        icon: (color) => <Settings fill={color} />,
        company: true,
        journalist: true,
    },
    {
        title: 'Breaking News',
        url: 'breaking-news',
        icon: (color) => <Rss fill={color} />,
        company: false,
        journalist: false,
    },
];

export default navigationData;
