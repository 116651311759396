import {
    authSuccess,
    handleAuthorization,
    logout,
    setAuthDetails,
} from 'store/auth';
import {
    getMe,
    setAuthToken,
    setNewToken,
    setRefreshTokenInterceptor,
} from 'api';
import parseJwt from './parse-jwt';
import { handleResetData } from 'store/company';
import { deleteCookie, getCookie } from 'cookies-next';
import { options } from './cookie';
import {
    AUTH_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
} from '../data/globalConstants';

export default async function auth(store, req, res, params = null) {
    const refreshToken = getCookie(REFRESH_TOKEN_COOKIE, { req, res });
    setRefreshTokenInterceptor(store.dispatch, req, res);

    if (!refreshToken) {
        return;
    }

    const token = getCookie(AUTH_TOKEN_COOKIE, { req, res });
    if (token) {
        setAuthToken(token);
    } else {
        try {
            await setNewToken(store.dispatch, req, res);
        } catch {
            deleteCookie(AUTH_TOKEN_COOKIE, { ...options, req, res });
            deleteCookie(REFRESH_TOKEN_COOKIE, { ...options, req, res });
            store.dispatch(logout());
            store.dispatch(handleResetData());
            return;
        }
    }

    const updatedRefreshToken = getCookie(REFRESH_TOKEN_COOKIE, { req, res });
    const { exp } = parseJwt(updatedRefreshToken);
    const currDate = new Date();
    const isTokenExpired = new Date(exp * 1000) < currDate;

    if (isTokenExpired) {
        deleteCookie(AUTH_TOKEN_COOKIE, { ...options, req, res });
        deleteCookie(REFRESH_TOKEN_COOKIE, { ...options, req, res });
        store.dispatch(logout());
        store.dispatch(handleResetData());
        return;
    }

    const user = (await getMe()).data.data;
    store.dispatch(setAuthDetails({ user }));

    if (req) {
        try {
            const updatedInfo = await getMe();

            const newToken = getCookie(AUTH_TOKEN_COOKIE, { req, res });
            store.dispatch(authSuccess(newToken));

            if (user?.deactivated === 1) {
                deleteCookie(AUTH_TOKEN_COOKIE, { ...options, req, res });
                deleteCookie(REFRESH_TOKEN_COOKIE, { ...options, req, res });
                store.dispatch(logout());
                store.dispatch(handleResetData());

                return;
            }

            if (params?.journalist) {
                await store.dispatch(
                    handleAuthorization((params && params.journalist) || null)
                );
            } else {
                await store.dispatch(
                    handleAuthorization((params && params.company) || null)
                );
            }
        } catch {
            deleteCookie(AUTH_TOKEN_COOKIE, { ...options, req, res });
            deleteCookie(REFRESH_TOKEN_COOKIE, { ...options, req, res });
            store.dispatch(logout());
            store.dispatch(handleResetData());
        }
    }
}
